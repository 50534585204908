// VIDEOSTICKY
(() => {
  const videoWrapper = document.querySelector(
    '.tc_video__video[data-sticky=true]',
  );
  const video = videoWrapper && videoWrapper.querySelector('video-js, video');

  const scrollHandler = () => {
    const rect = (videoWrapper || video).getBoundingClientRect();
    const notInView = rect.bottom / rect.height < 0;
    const hasStickyClass = video.classList.contains(
      'tv2-videojs-sticky--fixed',
    );

    notInView &&
      !hasStickyClass &&
      video.classList.add('tv2-videojs-sticky--fixed');

    !notInView &&
      hasStickyClass &&
      video.classList.remove('tv2-videojs-sticky--fixed');
  };

  video && window.addEventListener('scroll', scrollHandler);
})();
